import React from 'react';
interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled: boolean;
  name: string;
}

function Button({ name, disabled }: ButtonProps) {
  return (
    <button className="but" type="submit" disabled={disabled}>
      {disabled ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        name
      )}
    </button>
  );
}

export default Button;
