import React from 'react';

import email from '../../assets/img/email.svg';
import logo from '../../assets/img/logo-new.svg';

import './footer.css';

function Footer() {
  const logoSrc = window.document.domain === 'tech.maykor.com' ? logo : logo;

  return (
    <footer id="cont">
      <div className="container flex-b box">
        <img className="logo" src={logoSrc} alt="" />
        {/* <a href="" style={{ color: '#003791' }}>
          tech.maykor.com
        </a> */}
        <a className="mailto flex-s" href="mailto:info@m-core.pro">
          <img src={email} alt="" />
          info@m-core.pro
        </a>
        {/*         <div className="soc">
          <a
            href="https://instagram.com/maykor.official?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
           <a href="https://app.leadteh.ru/w/ty8" target="_blank" rel="noreferrer">
            <img src={telegram1} alt="" />
          </a> 
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
