import React, { useState } from 'react';
import { InputField } from '../../common/InputField';
import { sendForm } from '../../api';

import rec1 from '../../assets/img/rec1.svg';
import rec4 from '../../assets/img/rec4.svg';
import ad from '../../assets/img/ad.png';
import Button from '../../common/Button';

import './tarifs.css';
interface TariffsProps {
  setModal: (a: boolean) => void;
}

function Tariffs({ setModal }: TariffsProps) {
  const [valueTel, setValueTel] = useState('');
  const [valueName, setValueName] = useState('');
  const [valueMail, setValueMail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const data = [
      { title: 'Имя', value: valueName },
      { title: 'Телефон', value: valueTel },
      { title: 'Почта', value: valueMail },
    ];

    sendForm(data).then((res) => {
      if (res?.status === 'Ok') {
        setModal(true);
        setIsLoading(false);
      }
    });
  };
  return (
    <div className="tarif-container">
      <img className="deco hidden-xs" src={rec1} alt="" />
      <img className="deco visible-xs" src={rec4} alt="" />
      <div className="container block">
        <h3 className="headline">Варианты подписок на ИТ поддержку</h3>
        <div className="flex-b tarif-cont">
          <div className="tarif-bl flex-b">
            <div className="tarif">
              <h5>Ежемесячная</h5>
              <p>
                <b>1 000 ₽</b>
                <span>за 3 ПК</span>
              </p>
            </div>

            <div className="tarif">
              <h5>Ежеквартальная</h5>
              <p>
                <b>2 500 ₽</b>
                <span>за 3 ПК</span>
              </p>
            </div>

            <div className="tarif tarif-mod">
              <h5>Годовая подписка</h5>
              <p>
                <b>5 000 ₽</b>
                <span>
                  за 3 ПК + 1 выезд для <br />
                  диагностики бесплатно
                </span>
              </p>
            </div>

            <div className="tarif_mod">
              <p>
                Доплата за каждый ПК <br />
                <b>500 ₽ </b> <span>для всех подписок</span>
              </p>
              <p>
                Разовый выезд <br />
                диагностический <br />
                <b>400 ₽ </b> <span>для всех подписок</span>
              </p>
              <p>
                Ремонтно- <br />
                восстановительный <br />
                <b>1 500 ₽ </b> <span>для всех подписок</span>
              </p>
            </div>
          </div>

          <div id="tarif" className="tarif-form">
            <h4>
              Оформите заявку
              <br />
              на ИТ поддержку
            </h4>
            <form className=" head-form send-form" onSubmit={onSubmitHandler}>
              <InputField
                required
                type="text"
                name="Имя"
                placeholder="Имя"
                value={valueName}
                setValue={setValueName}
              />
              <InputField
                required
                type="tel"
                name="Телефон"
                placeholder="+ 7 (ХХХ) ХХХ - ХХ - ХХ"
                mask="+7 (999) 999-99-99"
                value={valueTel}
                setValue={setValueTel}
              />
              <InputField
                type="email"
                name="email"
                placeholder="Почта"
                value={valueMail}
                setValue={setValueMail}
              />
              <Button name="Отправить заявку" disabled={isLoading} />

              <p className="polit">
                Нажимая на кнопку отправить, Вы соглашаетесь с политикой конфиденциальности и на
                обработку персональных данных
              </p>
            </form>
          </div>
        </div>

        <div className="flex-b aten-bl">
          <p className="aten">
            Оформив подписку, Вы сможете рассчитывать на оперативное решение IT проблем, услуга
            может быть подключена в дополнение к имеющимся IT-ресурсам
          </p>
          <div className="flex-s tw">
            <img src={ad} alt="" />
            <div>
              <p>Программа для удаленного подключения к Вашему компьютеру</p>
              <a
                href="https://anydesk.com/ru"
                className="but add-hov"
                target="_blank"
                rel="noreferrer"
              >
                Скачать AnyDesk
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tariffs;
