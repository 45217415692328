import React from 'react';

import ok from '../../assets/img/ok.svg';
import './modal.css';

interface ModalProps {
  state: boolean;
  setModal: (a: boolean) => void;
}
function index({ state, setModal }: ModalProps) {
  if (state) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
  }
  return (
    <>
      {state && (
        <div className="modal" id="thx" tabIndex={-1} role="dialog">
          <div className="window" role="document">
            <div className="content">
              <img src={ok} alt="" />
              <p>Мы приняли вашу заявку и позвоним вам в ближайшее время. Будьте на связи!</p>
              <button
                type="button"
                className="but"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal(false)}
              >
                Буду ждать
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default index;
