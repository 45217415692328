import React from 'react';

import ico1 from '../../assets/img/ico1.svg';
import ico2 from '../../assets/img/ico2.svg';
import ico3 from '../../assets/img/ico3.svg';
import ico4 from '../../assets/img/ico4.svg';
import deco1 from '../../assets/img/deco1.svg';

import './plates.css';

function Plates() {
  interface PlateData {
    plateID: number;
    title: string;
    text: string;
    icon: string;
  }

  const plates: PlateData[] = [
    {
      plateID: 1,
      title: 'Поддержка 24/7',
      text: 'В любое время суток мы на связи и готовы оперативно решить Ваш вопрос',
      icon: ico1,
    },
    {
      plateID: 2,
      title: 'Быстро реагируем',
      text: 'Время реакции – не более 10 минут Время удаленного решения вопросов – не более 6 часов',
      icon: ico2,
    },
    {
      plateID: 3,
      title: 'Экспертность',
      text: 'Только квалифицированные специалисты, которые постоянно проходят сертификацию',
      icon: ico3,
    },
    {
      plateID: 4,
      title: 'Выгода',
      text: 'Не нужно тратить ресурсы компании на привлечение IT специалиста',
      icon: ico4,
    },
  ];

  return (
    <div className="icons pad-bot">
      <img src={deco1} className="deco hidden-xs" alt="" />
      <div className="container flex-b flex-p icon-bl">
        {plates.map((plate) => (
          <div className="icon-it" key={plate.plateID}>
            <img src={plate.icon} alt="" />
            <h4>{plate.title}</h4>
            <p>{plate.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Plates;
