import React, { useState } from 'react';
import InputMask from 'react-input-mask';

interface InputFieldProps {
  type: 'tel' | 'text' | 'email';
  name: string;
  placeholder: string;
  required?: boolean;
  value: string;
  mask?: string;
  setValue: (e: string) => void;
}

export const emailIsValid = (email: string) => {
  return /^[a-zA-Zа-яёА-ЯЁ0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Zа-яёА-ЯЁ0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Zа-яёА-ЯЁ0-9](?:[a-zA-Zа-яёА-ЯЁ0-9-]*[a-zA-Zа-яёА-ЯЁ0-9])?\.)+[a-zA-Zа-яёА-ЯЁ0-9](?:[a-zA-Zа-яёА-ЯЁ0-9-]*[a-zA-Zа-яёА-ЯЁ0-9])?$/.test(
    email
  );
};

export const phoneIsValid = (phone: string) => {
  return !phone.includes('_');
};

export const InputField = (props: InputFieldProps) => {
  const { type, name, placeholder, required = false, value, setValue, mask } = props;
  const [isTouched, setTouched] = useState(false);
  const [isValid, setValid] = useState(true);
  const [error, setError] = useState('');

  const validate = (val: string) => {
    if (type === 'email') {
      if (emailIsValid(val)) setValid(true);
      else {
        setValid(false);
        setError('Неправильно набрана почта');
      }
    }
    if (type === 'tel') {
      if (phoneIsValid(val)) setValid(true);
      else {
        setValid(false);
        setError('Неправильно набран номер');
      }
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    validate(e.target.value);
  };

  return (
    <div style={{ marginBottom: '30px', position: 'relative', display: 'inline-block' }}>
      {mask ? (
        <InputMask
          className="input"
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          mask={mask}
          onChange={onChangeHandler}
          onBlur={() => setTouched(true)}
        />
      ) : (
        <input
          type={type}
          name={name}
          className="input"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChangeHandler}
          onBlur={() => setTouched(true)}
        />
      )}

      {required && isTouched && !value && (
        <p className="error-field" style={{ position: 'absolute', bottom: -20, left: 0 }}>
          Введите значение
        </p>
      )}
      {isTouched && !isValid && <p className="error-field">{error}</p>}
    </div>
  );
};
