export const sendForm = async (data) => {
  const sendFormUrl = process.env.REACT_APP_SEND_FORM_URL;

  const response = await fetch(sendFormUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data,
      id: 46,
      location: window.location.href,
    }),
  });

  return response.json();
};
