import React, { useState, useEffect } from 'react';

import Plates from './components/Plates';
import Header from './components/Header';
import HeaderContent from './components/HeaderContent';
import Tariffs from './components/Tariffs';
import WorkScope from './components/WorkScope';
import Partners from './components/Partners';
import Footer from './components/Footer';
import Modal from './components/Modal';

function App() {
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = (visible: boolean) => setOpenModal(visible);

  const script = document.createElement('script');
  script.id = 'ymc';
  script.async = true;
  script.innerHTML = ` (function (m, e, t, r, i, k, a) {
    m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) }; m[i].l = 1 * new Date(); k = e.createElement(t),
      a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
  })(window, document, "script"
    , "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(87978597, "init", {
      clickmap: true, trackLinks: true,
      accurateTrackBounce: true, webvisor: true
    });`;

  useEffect(() => {
    if (window.document.domain === 'tech.maykor.com') {
      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <Header />
      <HeaderContent setModal={openModalHandler} />
      <Plates />
      <Tariffs setModal={openModalHandler} />
      <WorkScope />
      <Partners />
      <Footer />
      <Modal state={openModal} setModal={openModalHandler} />
    </>
  );
}

export default App;
