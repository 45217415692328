import React, { useState, useEffect } from 'react';

import logo from '../../assets/img/logo-new.svg';
import menu from '../../assets/img/menu.svg';

import './header.css';

function Header() {
  const [hasBackground, setHasBackground] = useState(false);
  const [visibleBurger, setVisibleBurger] = useState(false);
  const [buttonColored, setButtonColored] = useState(false);

  const logoSrc = window.document.domain === 'tech.maykor.com' ? logo : logo;
  const setButtonColoredHandler = () => setButtonColored(true);
  const toggleVisibleMenu = () => setVisibleBurger((prev) => !prev);

  const onScrollCb = () => {
    if (window.scrollY >= 10 || window.pageYOffset >= 10) {
      setHasBackground(true);
    } else {
      setHasBackground(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', onScrollCb);

    return () => window.removeEventListener('scroll', onScrollCb);
  }, []);

  return (
    <header className={`fixed ${hasBackground ? 'style' : ''}`}>
      <div className="container head flex-b">
        <img className="logo" src={logoSrc} alt="" />
        <a className="tel hidden" href="tel:88002001483">
          8 800 200 14 83{' '}
        </a>

        <nav className={`flex-b ${visibleBurger ? 'burger' : ''}`}>
          <a href="#prod" className="add-hov" onClick={toggleVisibleMenu}>
            О нашем продукте
          </a>
          <a href="#part" className="add-hov" onClick={toggleVisibleMenu}>
            Партнерам
          </a>
          <a href="#cont" className="add-hov" onClick={toggleVisibleMenu}>
            Контакты
          </a>
          <a href="#tarif" className="visible-xs add-hov" onClick={toggleVisibleMenu}>
            Стать клиентом
          </a>
        </nav>
        <a
          href="#tarif"
          className={`but scroll-link hidden-xs but_mod ${buttonColored ? 'but_active' : ''}`}
          onClick={setButtonColoredHandler}
        >
          Стать клиентом
        </a>

        <div>
          <a className="tel shown" href="tel:88002001483">
            8 800 200 14 83{' '}
          </a>
          <a className="mailto" href="mailto:info@m-core.pro">
            info@m-core.pro
          </a>
        </div>

        <div className="nav-control">
          <img src={menu} alt="" onClick={toggleVisibleMenu} />
        </div>
      </div>
    </header>
  );
}

export default Header;
