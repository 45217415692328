import React from 'react';

import rec2 from '../../assets/img/rec2.svg';
import image2_mirror from '../../assets/img/image2_mirror.svg';
import Telegram from '../../assets/img/Telegram.svg';
import whatsApp from '../../assets/img/whatsApp_white.svg';

import './partners.css';

function Partners() {
  return (
    <div id="part" className="part-cont">
      <div className="container block">
        <div className="flex-b part-bl">
          <img className="part-img" src={image2_mirror} alt="" />
          <div className="partner">
            <h3 className="headline">Партнерам</h3>
            <p>
              Мы постоянно в поиске новых решений для развития нашего продукта. По вопросам
              сотрудничества Вы можете обратиться к руководителю проекта, Евгении Полянской:
            </p>
            <a className="mailto" href="mailto:sales@m-core.pro">
              sales@m-core.pro
            </a>
            <br />
            <br />
            <div className="flex-s soc">
              <a href="https://t.me/polyanskayaes">
                <img src={Telegram} alt="" />
              </a>
              {/* <a>
                <img src={whatsApp} alt="" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
