import { useState } from 'react';
import { InputField } from '../../common/InputField';
import Button from '../../common/Button';
import { sendForm } from '../../api';

import image1 from '../../assets/img/image1.png';
import './headerContent.css';

interface HeaderContentProps {
  setModal: (a: boolean) => void;
}

function HeaderContent({ setModal }: HeaderContentProps) {
  const [valueTel, setValueTel] = useState('');
  const [valueName, setValueName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const data = [
      { title: 'Имя', value: valueName },
      { title: 'Телефон', value: valueTel },
    ];

    sendForm(data).then((res) => {
      if (res?.status === 'Ok') {
        setModal(true);
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="head-cont">
      <div className="container">
        <div className="flex-b head-text">
          <div>
            <h1>ИТ-аутсорсинг по всей России</h1>
            <p>
              Mcore - Ваш ИТ помощник. Мы предоставляем комплекс решений - от готовых до
              индивидуальных проектов.
            </p>
          </div>
          <img className="hidden-xs cont-image" src={image1} alt="" />
        </div>
        <form className=" head-form send-form flex-s" onSubmit={onSubmitHandler}>
          <InputField
            required
            type="text"
            name="Имя"
            placeholder="Имя"
            value={valueName}
            setValue={setValueName}
          />
          <InputField
            required
            type="tel"
            name="Телефон"
            placeholder="+ 7 (ХХХ) ХХХ - ХХ - ХХ"
            mask="+7 (999) 999-99-99"
            value={valueTel}
            setValue={setValueTel}
          />
          <Button name="Отправить заявку" disabled={isLoading} />
        </form>
        <img className="visible-xs image" src={image1} alt="" />
      </div>
    </div>
  );
}

export default HeaderContent;
