import React from 'react';
import cluster from 'assets/img/cluster.svg';
import './workScope.css';

function WorkScope() {
  return (
    <div id="prod" className="container block work-top" style={{ paddingTop: 100 }}>
      <div className="flex-b work-bl">
        <div className="work">
          <h1 className="headline">Наши решения</h1>
          <p>
            Поддержка и облуживание (техническая поддержка офисной техники, удаленный Help Desk)
          </p>
          <p>Импортозамещение (оценка возможности перехода на российское ПО)</p>
          <p>Аудит ИТ инфраструктуры (анализ текущей ИТ инфраструктуры) </p>
          <p>ИТ аутсорсинг (полный цикл сопровождения ИТ инфраструктуры вашего предприятия)</p>
          <p>Удаленная работа (перевод офиса на удаленную работу) </p>
          <p>Обслуживание 1С </p>
          <p>Настройка ЭЦП</p>
          <h2>Мы разрабатываем индивидуальное решение под каждого нашего клиента в сфере ИТ.</h2>
        </div>
        <img className="work-img" src={cluster} alt="" />
      </div>
    </div>
  );
}

export default WorkScope;
